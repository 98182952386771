var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"login-box",attrs:{"data-cy":"login-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-password-old","label":"","label-for":"input-password-old","description":""}},[_c('b-form-group',{attrs:{"id":"group-mobile","label":"","label-for":"input-mobile","description":""}},[_c('b-form-input',{staticClass:"from-input input-mobile box-shadow3",class:{
          'border-danger': !_vm.isDarkMode,
          'bg-dark text-light placeholder-light ': _vm.isDarkMode,
          'border-danger': _vm.errorMessages
        },attrs:{"id":"input-mobile","type":"number","required":"","placeholder":"Enter Mobile","state":_vm.$v.form.mobile.$dirty ? !_vm.$v.form.mobile.$error : null,"data-cy":"login-mobile"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-mobile",attrs:{"id":"input-mobile-invalid","data-cy":"login-mobile-invalid"}},[_vm._v(" Please enter your mobile number. ")])],1),_c('b-form-input',{staticClass:"from-input input-password-old box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-password-old","type":"password","required":"","placeholder":"Enter Old password","state":_vm.$v.form.passwordOld.$dirty ? !_vm.$v.form.passwordOld.$error : null,"data-cy":"login-password-old"},model:{value:(_vm.form.passwordOld),callback:function ($$v) {_vm.$set(_vm.form, "passwordOld", $$v)},expression:"form.passwordOld"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-password",attrs:{"id":"input-password-invalid","data-cy":"login-password-old-invalid"}},[_vm._v(" Please enter your old password. ")])],1),_c('b-form-group',{attrs:{"id":"group-password-new","label":"","label-for":"input-password-new","description":""}},[_c('b-form-input',{staticClass:"from-input input-password-new box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-password-new","type":"password","required":"","placeholder":"Enter New password","state":_vm.$v.form.passwordNew.$dirty ? !_vm.$v.form.passwordNew.$error : null,"data-cy":"login-password-new"},model:{value:(_vm.form.passwordNew),callback:function ($$v) {_vm.$set(_vm.form, "passwordNew", $$v)},expression:"form.passwordNew"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-password",attrs:{"id":"input-password-new-invalid","data-cy":"login-password-new-invalid"}},[_vm._v(" Please enter your new password. ")])],1),(_vm.successMessages || _vm.errorMessages)?[_c('b-row',{staticClass:"mb-2"},[(_vm.successMessages)?_c('b-col',{staticClass:"text-primary message-col",attrs:{"data-cy":"login-success-message"}},[_vm._v(_vm._s(_vm.successMessages))]):_vm._e(),(_vm.errorMessages)?_c('b-col',{staticClass:"text-danger message-col",attrs:{"data-cy":"login-error-message"}},[_vm._v(_vm._s(_vm.errorMessages))]):_vm._e()],1)]:_vm._e(),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"btn-login box-shadow2",attrs:{"data-cy":"login-button","type":"submit","variant":_vm.isDarkMode ? 'outline-primary' : 'primary',"disabled":_vm.$v.form.$invalid || _vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_vm._v(" Save ")])],1),_c('b-col',[_c('b-button',{staticClass:"btn-login box-shadow2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":_vm.goToLoginScreen}},[_vm._v(" Back ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }