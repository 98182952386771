<template>
  <div class="page-class page-login">
    <b-container class="my-5">
      <b-row align-v="center" align-h="center" align-content="center">
        <b-col class="text-center" sm="10" md="8" lg="5">
          <img src="../assets/images/logo.png" width="100" />
          <b-card-group deck class="mt-3">
            <b-card :class="{ 'box-shadow2': !isDarkMode, 'box-shadow2 text-light dark-div': isDarkMode }">
              <h1>Password Change</h1>
              <p class="text-muted">Change Your Password</p>
              <password-change-box />
            </b-card>
          </b-card-group>
          <br />
          &copy; {{ thisYear }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PasswordChangeBox from '@/components/PasswordChangeBox.vue';

export default {
  name: 'ChangePassword',
  components: { PasswordChangeBox },
  metaInfo: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: 'Login backend with your account.'
      }
    ]
  },
  mounted() {
    if (this.$route.query.messageKey) {
      this.handleAuthMessageKey({ messageKey: this.$route.query.messageKey });
    }
  },
  methods: {
    ...mapActions('auth', ['handleAuthMessageKey'])
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    thisYear() {
      return new Date().getFullYear();
    }
  }
};
</script>
