var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"login-box",attrs:{"data-cy":"login-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-mobile","label":"","label-for":"input-mobile","description":""}},[_c('b-form-input',{staticClass:"from-input input-mobile box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-mobile","type":"number","required":"","placeholder":"Enter Mobile","state":_vm.$v.form.mobile.$dirty ? !_vm.$v.form.mobile.$error : null,"data-cy":"login-mobile","formatter":_vm.limit},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-mobile",attrs:{"id":"input-mobile-invalid","data-cy":"login-mobile-invalid"}},[_vm._v(" Please enter your mobile number. ")])],1),_c('b-form-group',{attrs:{"id":"group-password","label":"","label-for":"input-password","description":""}},[_c('b-form-input',{staticClass:"from-input input-password box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-password","type":"password","required":"","placeholder":"Enter password","state":_vm.$v.form.password.$dirty ? !_vm.$v.form.password.$error : null,"data-cy":"login-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-password",attrs:{"id":"input-password-invalid","data-cy":"login-password-invalid"}},[_vm._v(" Please enter your password. ")])],1),(_vm.successMessages || _vm.errorMessages)?[_c('b-row',{staticClass:"mb-2"},[(_vm.successMessages)?_c('b-col',{staticClass:"text-primary message-col",attrs:{"data-cy":"login-success-message"}},[_vm._v(_vm._s(_vm.successMessages))]):_vm._e(),(_vm.errorMessages)?_c('b-col',{staticClass:"text-danger message-col",attrs:{"data-cy":"login-error-message"}},[_vm._v(_vm._s(_vm.errorMessages))]):_vm._e()],1)]:_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-button',{staticClass:"box-shadow2 btn-login",attrs:{"data-cy":"login-button","type":"submit","variant":_vm.isDarkMode ? 'outline-primary' : 'primary',"disabled":_vm.$v.form.$invalid || _vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_vm._v(" Login ")])],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-button',{staticClass:"btn-login box-shadow3",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'light'},on:{"click":_vm.goToPasswordChangeScreen}},[_vm._v(" Change Password ")])],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-button',{staticClass:"btn-login box-shadow3",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'light'},on:{"click":_vm.goToPasswordResetScreen}},[_vm._v(" Reset Password ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }