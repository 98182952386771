<template>
  <b-form @submit.stop.prevent="onSubmit" class="login-box" data-cy="login-form">
    <b-form-group id="group-password-old" label label-for="input-password-old" description>
      <b-form-group id="group-email" label label-for="input-email" description>
        <div style="display: flex">
          <b-form-input
            id="input-email"
            v-model="form.email"
            type="text"
            required
            placeholder="Enter Email"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            data-cy="login-email"
            class="from-input input-email box-shadow3"
            :class="{
              'border-danger': !isDarkMode,
              'bg-dark text-light placeholder-light ': isDarkMode,
              'border-danger': errorMessages
            }"
            style="max-width: 299px"
          ></b-form-input>
          <b-button
            :disabled="!form.email ? true : false"
            class="getOtp box-shadow2"
            :variant="isDarkMode ? 'outline-success' : 'success'"
            @click="GetOtp"
            >Get OTP</b-button
          >
        </div>

        <b-form-invalid-feedback
          id="input-email-invalid"
          data-cy="login-email-invalid"
          class="invalid-feedback invalid-feedback-email"
        >
          Please enter your email.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-input
        id="input-otp"
        type="number"
        v-model="form.otp"
        required
        placeholder="Enter OTP"
        class="from-input input-otp box-shadow3"
        :class="{
          'border-danger': !isDarkMode,
          'bg-dark text-light placeholder-light ': isDarkMode,
          'border-danger': errorMessages
        }"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="group-password-new" label label-for="input-password-new" description>
      <b-form-input
        id="input-password-new"
        type="password"
        v-model="form.passwordNew"
        required
        placeholder="Enter New password"
        :state="$v.form.passwordNew.$dirty ? !$v.form.passwordNew.$error : null"
        data-cy="login-password-new"
        class="from-input input-password-new box-shadow3"
        :class="{
          'border-danger': !isDarkMode,
          'bg-dark text-light placeholder-light ': isDarkMode,
          'border-danger': errorMessages
        }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-password-new-invalid"
        data-cy="login-password-new-invalid"
        class="invalid-feedback invalid-feedback-password"
      >
        Please enter your new password.
      </b-form-invalid-feedback>
    </b-form-group>

    <template v-if="successMessages || errorMessages">
      <b-row class="mb-2">
        <b-col v-if="successMessages" data-cy="login-success-message" class="text-primary message-col">{{
          successMessages
        }}</b-col>
        <b-col v-if="errorMessages" data-cy="login-error-message" class="text-danger message-col">{{
          errorMessages
        }}</b-col>
      </b-row>
    </template>

    <div class="flex-container">
      <b-form-group id="group-captcha-new" label label-for="input-captcha-new" description>
        <b-form-input
          id="input-captcha-new"
          type="text"
          v-model="enteredText"
          required
          placeholder="Enter captcha"
          :state="$v.enteredText.$dirty ? !$v.enteredText.$error : null"
          data-cy="login-captcha-new"
          class="from-input input-captcha-new box-shadow3"
          :class="{
            'border-danger': !isDarkMode,
            'bg-dark text-light placeholder-light': isDarkMode,
            'border-danger': errorMessages
          }"
        ></b-form-input>

        <b-form-invalid-feedback
          id="input-captcha-new-invalid"
          data-cy="login-captcha-new-invalid"
          class="invalid-feedback invalid-feedback-captcha"
        >
          Wrong Captcha.
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <canvas id="myCanvas" ref="myCanvas"> </canvas>
    <font-awesome-icon @click="generateCaptchaOnClick" :icon="['fas', 'retweet']" class="retweet" />
    <b-row class="mt-2">
      <b-col>
        <b-button
          data-cy="login-button"
          class="btn-login box-shadow2"
          type="submit"
          :variant="isDarkMode ? 'outline-primary' : 'primary'"
          :disabled="$v.form.$invalid || loading"
        >
          <span class="spinner spinner-white" v-if="loading"></span>
          Save
        </b-button>
      </b-col>
      <b-col>
        <b-button
          @click="goToLoginScreen"
          class="btn-login box-shadow2"
          :variant="isDarkMode ? 'outline-light' : 'secondary'"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'PasswordResetBox',
  data() {
    return {
      captchaText: '',
      enteredText: '',
      form: {
        email: '',
        passwordNew: '',
        otp: ''
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      passwordNew: {
        required,
        minLength: minLength(6)
      }
    },
    enteredText: {
      required,
      minLength: minLength(6)
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, silent: true });
    }
    this.captchaText = this.generateRandomText(6);
    this.generateCaptcha();
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['loading']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('user', ['passwordReset', 'getOtp']),
    clearCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    generateCaptcha() {
      this.clearCanvas();
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      ctx.font = '70px Arial';
      ctx.filter = 'blur(4px)';
      ctx.fillText(this.captchaText, 0, 80);
    },
    generateCaptchaOnClick() {
      this.captchaText = this.generateRandomText(6);
      this.generateCaptcha();
    },
    generateRandomText(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    onSubmit() {
      if (this.enteredText === this.captchaText) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        const password = {
          passwordNew: this.form.passwordNew
        };
        // Form submit logic
        this.passwordReset({
          query: {
            otp: this.form.otp,
            email: this.form.email
          },
          password,
          router,
          redirectUrl: '/login'
        });
      } else {
        Vue.swal({
          title: 'Captcha Not Matched',
          icon: 'error',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.value) {
            this.enteredText = '';
            this.captchaText = this.generateRandomText(6);
            this.generateCaptcha();
          }
        });
      }
    },
    GetOtp() {
      this.getOtp({
        email: this.form.email
      });
    },
    goToLoginScreen() {
      router.push(`/login`);
    }
  }
};
</script>
<style scoped>
.getOtp {
  margin-left: 10px;
  width: 97px;
}
#myCanvas {
  width: 330px;
  height: 40px;
}
.retweet {
  margin-bottom: 15px;
  font-size: 25px;
}
</style>
