var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"login-box",attrs:{"data-cy":"login-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-password-old","label":"","label-for":"input-password-old","description":""}},[_c('b-form-group',{attrs:{"id":"group-email","label":"","label-for":"input-email","description":""}},[_c('div',{staticStyle:{"display":"flex"}},[_c('b-form-input',{staticClass:"from-input input-email box-shadow3",class:{
            'border-danger': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light ': _vm.isDarkMode,
            'border-danger': _vm.errorMessages
          },staticStyle:{"max-width":"299px"},attrs:{"id":"input-email","type":"text","required":"","placeholder":"Enter Email","state":_vm.$v.form.email.$dirty ? !_vm.$v.form.email.$error : null,"data-cy":"login-email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-button',{staticClass:"getOtp box-shadow2",attrs:{"disabled":!_vm.form.email ? true : false,"variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":_vm.GetOtp}},[_vm._v("Get OTP")])],1),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-email",attrs:{"id":"input-email-invalid","data-cy":"login-email-invalid"}},[_vm._v(" Please enter your email. ")])],1),_c('b-form-input',{staticClass:"from-input input-otp box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-otp","type":"number","required":"","placeholder":"Enter OTP"},model:{value:(_vm.form.otp),callback:function ($$v) {_vm.$set(_vm.form, "otp", $$v)},expression:"form.otp"}})],1),_c('b-form-group',{attrs:{"id":"group-password-new","label":"","label-for":"input-password-new","description":""}},[_c('b-form-input',{staticClass:"from-input input-password-new box-shadow3",class:{
        'border-danger': !_vm.isDarkMode,
        'bg-dark text-light placeholder-light ': _vm.isDarkMode,
        'border-danger': _vm.errorMessages
      },attrs:{"id":"input-password-new","type":"password","required":"","placeholder":"Enter New password","state":_vm.$v.form.passwordNew.$dirty ? !_vm.$v.form.passwordNew.$error : null,"data-cy":"login-password-new"},model:{value:(_vm.form.passwordNew),callback:function ($$v) {_vm.$set(_vm.form, "passwordNew", $$v)},expression:"form.passwordNew"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-password",attrs:{"id":"input-password-new-invalid","data-cy":"login-password-new-invalid"}},[_vm._v(" Please enter your new password. ")])],1),(_vm.successMessages || _vm.errorMessages)?[_c('b-row',{staticClass:"mb-2"},[(_vm.successMessages)?_c('b-col',{staticClass:"text-primary message-col",attrs:{"data-cy":"login-success-message"}},[_vm._v(_vm._s(_vm.successMessages))]):_vm._e(),(_vm.errorMessages)?_c('b-col',{staticClass:"text-danger message-col",attrs:{"data-cy":"login-error-message"}},[_vm._v(_vm._s(_vm.errorMessages))]):_vm._e()],1)]:_vm._e(),_c('div',{staticClass:"flex-container"},[_c('b-form-group',{attrs:{"id":"group-captcha-new","label":"","label-for":"input-captcha-new","description":""}},[_c('b-form-input',{staticClass:"from-input input-captcha-new box-shadow3",class:{
          'border-danger': !_vm.isDarkMode,
          'bg-dark text-light placeholder-light': _vm.isDarkMode,
          'border-danger': _vm.errorMessages
        },attrs:{"id":"input-captcha-new","type":"text","required":"","placeholder":"Enter captcha","state":_vm.$v.enteredText.$dirty ? !_vm.$v.enteredText.$error : null,"data-cy":"login-captcha-new"},model:{value:(_vm.enteredText),callback:function ($$v) {_vm.enteredText=$$v},expression:"enteredText"}}),_c('b-form-invalid-feedback',{staticClass:"invalid-feedback invalid-feedback-captcha",attrs:{"id":"input-captcha-new-invalid","data-cy":"login-captcha-new-invalid"}},[_vm._v(" Wrong Captcha. ")])],1)],1),_c('canvas',{ref:"myCanvas",attrs:{"id":"myCanvas"}}),_c('font-awesome-icon',{staticClass:"retweet",attrs:{"icon":['fas', 'retweet']},on:{"click":_vm.generateCaptchaOnClick}}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"btn-login box-shadow2",attrs:{"data-cy":"login-button","type":"submit","variant":_vm.isDarkMode ? 'outline-primary' : 'primary',"disabled":_vm.$v.form.$invalid || _vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_vm._v(" Save ")])],1),_c('b-col',[_c('b-button',{staticClass:"btn-login box-shadow2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":_vm.goToLoginScreen}},[_vm._v(" Back ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }